import React from "react";

import Layout from "../../components/layout";
import { Seo } from "../../components";

import image25 from "../../images/about/image25.jpg";

const CSILogoMotto = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="CSI Logo and Motto" />
      <div className="about">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="about-subtitle">CSI Logo and Motto</h3>
              <div className="row">
                <div className="col">
                  <div className="clearfix">
                    <img
                      src={image25}
                      className="col-12 col-md-3 float-sm-start pe-md-4"
                      alt="image25"
                    ></img>
                    <p>
                      The Logo of the Church of South India is an important and
                      significant as the formation of the CSI through the
                      process of unification. The Lotus and the Cross used in
                      the Logo possess a rich cultural history and heritage
                      which will help us in the understanding of the
                      significance of the unification and our call and mission
                      as members of this great and unique church. <br />
                      <br />
                      The Lotus, a typical Indian flower, in Mythology is
                      supposed to be not only a temple flower but also the seat
                      of the creator. Pankajam one of the Indian names given to
                      the Lotus has a very significant meaning that it is born
                      in mud. The Lotus blooms at sunrise and withers at sunset,
                      in other words it lives as long as it can receive the
                      sunrays hence it is also called Thammipuvu meaning flower
                      of the sun. Whether it means born in the mud or flower of
                      the sun to the Indian Church, it stands to be interpreted
                      as symbolizing the composition, nature and role of the
                      members of the Church of South India in the bond of the
                      union and in the context of being indigenous and also
                      being dependent on the grace of God.
                      <br />
                      <br />
                      The petals of the Lotus and the arms of the Cross are
                      beautifully intertwined together with the fiery tongues of
                      the Holy Spirit. This again is an authentic Indian
                      expression of peoples communion with God. The colours
                      being red (for life), purple (for piety and
                      ecclesiastical) and the white backdrop (for purity)
                      implicitly communicate the nature of the mystical union,
                      where, an inseparable companionship is established which,
                      again is a typical Indian thought form.
                      <br />
                      <br />
                      The words THAT THEY ALL MAY BE ONE, CHURCH OF SOUTH INDIA
                      are embossed in a circle round the Lotus and the Cross.
                      The words, as we know, are taken from the high priestly
                      prayer of our Lord Jesus Christ who prayed not only for
                      the Church but also for the whole world. This universality
                      is expressed by placing the words in a form of a circle
                      symbolizing not only the universe but also the inclusive
                      nature of the Church.
                      <br />
                      <br />
                      That they all may be one; as thou, father, art in me, and
                      I in thee that they also may be one in us...... (John
                      17:21) is an inclusive affirmation showing explicitly that
                      Christ is the Head and the Church, His body. It also
                      symbolizes the prayer of the Church that not only Churches
                      need to be united but all people of this country which can
                      be interpreted as a meaningful prayer for national
                      integration.
                      <br />
                      <br />
                      The imposing central position of the cross in the Logo
                      conveys the idea that the indefatigable, self less supreme
                      sacrifice of Jesus on the cross is the basis of the Church
                      and its faith. The cross runs through the Lotus depicting
                      its cleansing act working in the hearts of the members of
                      the Church and restoring them to the pristine purity of
                      heaven.
                      <br />
                      <br />
                      The four ends of the cross made of equal size symbolizes
                      the equality and also the contextual peaceful co-existence
                      and communal harmony. The Church of South India gratefully
                      acknowledges the initiative taken by the Officers and the
                      Executive Committee Members coinciding with the Silver
                      Jubilee celebration of the CSI in 1972.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CSILogoMotto;
